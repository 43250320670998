<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="addUserModal"
      :title="type === 'add' ? `${$t('Add Transporter')}`: `${$t('Edit Transporter')}`"
      :buttons-hidden="true">
      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required|alpha'" :label-placeholder="$t('First Name')" name="first name" v-model="data.first_name" class="mt-10 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('first name')">{{ errors.first('first name') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required|alpha'" :label-placeholder="$t('Last Name')" name="last name" v-model="data.last_name" class="mt-10 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('last name')">{{ errors.first('last name') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input :disabled="type === 'edit'" v-validate="'required|numeric|max:14'" name="national ID" :label-placeholder="$t('National ID')" v-model="data.national_id" class="mt-10 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('national ID')">{{ errors.first('national ID') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" name="phone nmber" :label-placeholder="$t('Phone Number')" v-model="data.phone_number" class="mt-10 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('phone nmber')">{{ errors.first('phone nmber') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button v-if="type === 'add'" @click="addUser" class="active-btn btn">{{ $t('Add') }}</button>
        <button v-else @click="updateUser" class="active-btn btn">{{ $t('Save') }}</button>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import i18nData from '../../../i18n/i18nData.js'

export default {
  props: ['addUserModal', 'type', 'data'],
  data () {
    return {
      apiURL: 'api/v1/users/transporters/',
      editMode: true,
      user: {
        first_name: '',
        last_name: '',
        national_id: '',
        phone_number: ''
      }
    }
  },
  watch: {
    type () {
      if (this.type === 'add') {
        this.user = Object.assign({}, this.data)
      }
    }
  },
  methods: {
    updateUser () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (!this.isOnline) this.$emit('addUserModal', false)
          sendRequest(false, false, this, `${this.apiURL}${this.data.uid}/`, 'patch', this.data, true,
            () => {
              this.$vs.notify({
                color:'success',
                title: i18nData[this.$i18n.locale]['Success'],
                text: i18nData[this.$i18n.locale]['Transporter'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
              this.$emit('addUserModal', false)
              this.$emit('loadData')
            }
          )
        }
      })
    },
    addUser () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (!this.isOnline) this.$emit('addUserModal', false)
          sendRequest(false, false, this, this.apiURL, 'post', this.data, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Transporter'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.$emit('addUserModal', false)
              this.$emit('loadData')
            }
          )
        }
      })
    },
    closeModal () {
      this.$emit('addUserModal', false)
      this.$emit('loadData')
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss"
</style>